import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=6b6390f3&scoped=true"
import script from "./TheHeader.vue?vue&type=script&lang=js"
export * from "./TheHeader.vue?vue&type=script&lang=js"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=6b6390f3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6390f3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgUapColor: require('/app/components/svg/UapColor.vue').default,MainNav: require('/app/components/MainNav.vue').default,SubNav: require('/app/components/SubNav.vue').default,SvgBurger: require('/app/components/svg/Burger.vue').default,SvgClose: require('/app/components/svg/Close.vue').default})

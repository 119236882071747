import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=daeb361e&scoped=true"
import script from "./TheFooter.vue?vue&type=script&lang=js"
export * from "./TheFooter.vue?vue&type=script&lang=js"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=daeb361e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daeb361e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgUap: require('/app/components/svg/Uap.vue').default,MainNav: require('/app/components/MainNav.vue').default,SocialMedias: require('/app/components/SocialMedias.vue').default,SubNav: require('/app/components/SubNav.vue').default,LegalNav: require('/app/components/LegalNav.vue').default,SvgNmedia: require('/app/components/svg/Nmedia.vue').default})

import { render, staticRenderFns } from "./SocialMedias.vue?vue&type=template&id=c328e954&scoped=true"
import script from "./SocialMedias.vue?vue&type=script&lang=js"
export * from "./SocialMedias.vue?vue&type=script&lang=js"
import style0 from "./SocialMedias.vue?vue&type=style&index=0&id=c328e954&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c328e954",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgFacebook: require('/app/components/svg/Facebook.vue').default,SvgLinkedin: require('/app/components/svg/Linkedin.vue').default})
